import React from "react";
import { Link } from "gatsby";

import imgL1Logo from "../../assets/image/dao-it-board.png";
import imgL1LogoWhite from "../../assets/image/dao-it-board.png";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <h4>
          <img src={imgL1LogoWhite} alt="dao it board" style={{width: "12%"}} /> Stand Up AI Comedy
        </h4>
      ) : (
        <h4>
          <img src={imgL1Logo} alt="dao it board" style={{width: "12%"}} /> Stand Up AI Comedy
        </h4>
      )}
    </Link>
  );
};

export default Logo;
